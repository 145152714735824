import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import momentFormat from 'ember-moment/computeds/format';

export default Component.extend({
  tagName: '',

  session: service(),

  country: alias('session.currentCountry'),

  updatedAt: momentFormat('country.updatedAt', 'DD MMMM YYYY'),
  updatedBy: computed('country.updatedBy.{firstName,lastName}', function () {
    return [
      this.get('country.updatedBy.firstName'),
      this.get('country.updatedBy.lastName')
    ].compact().join(' ');
  })
});
