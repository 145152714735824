import Sections from './-sections';
import moment from 'moment';

export default class ReportSerializer {
  constructor(report) {
    this.report = report;
  }

  async serialize() {
    let sections = new Sections();

    let reportDate = this.report.date ? moment(this.report.date).format('DD MMMM YYYY') : '';
    let prevReport = (await this.report.loadPrevReport()) || false;
    let prevReportPreparednessLevel = prevReport ? prevReport.preparednessLevel : '';
    let prevReportDate = prevReport ? moment(prevReport.date).format('DD MMMM YYYY') : '';
    let drillDate = this.report.drillDate ? moment(this.report.drillDate).format('DD MMMM YYYY') : '';

    sections.push('title', { value: this.report.displayName });
    sections.push('list', {
      title: 'Information',
      items: [
        { label: 'Country', value: await this.getCountry() },
        { label: 'Community', value: await this.getCommunity() },
        { label: 'Date', value: reportDate },
        { label: 'Activity type', value: this.report.drillType },
        { label: 'Level of preparedness', value: this.report.preparednessLevel },
        { label: 'Previous level of preparedness', value: prevReportPreparednessLevel },
        { label: 'Previous visit', value: prevReportDate },
        { label: 'Previous activity', value: drillDate }
      ]
    });

    //Quick Overview
    sections.push('text', {
      title: 'Overview',
      body: await this.overviewFor('executive-summary')
    });

    //Crisis Management Team
    sections.push('text', {
      title: 'Decision Makers',
      body: await this.overviewFor('decision-makers')
    });
    sections.push('text', {
      title: 'Professional Teams',
      body: await this.overviewFor('proffesional-teams')
    });
    sections.push('text', {
      title: 'Procedures',
      body: await this.overviewFor('procedures')
    });

    //Crisis Management Centre
    sections.push('text', {
      title: 'Crisis Management Centre Overview',
      body: await this.overviewFor('cmc-overview')
    });
    sections.push('text', {
      title: 'Equipment',
      body: await this.overviewFor('cmc-equipment')
    });
    sections.push('text', {
      title: 'Website',
      body: await this.overviewFor('cmc-website')
    });
    sections.push('text', {
      title: 'Hotline',
      body: await this.overviewFor('cmc-hotline')
    });

    //Previous drill
    sections.push('text', {
      title: 'Previous activity General Overview',
      body: await this.overviewFor('scenario')
    });
    sections.push('text', {
      title: 'Scenario and Used Materials',
      body: await this.overviewFor('scenario-materials')
    });
    sections.push('text', {
      title: 'Aim of the Exercise',
      body: await this.overviewFor('aim-of-exercise')
    });
    sections.push('text', {
      title: 'Results and Gaps',
      body: await this.overviewFor('results-and-gaps')
    });
    sections.push('text', {
      title: 'Recommendations',
      body: await this.overviewFor('recommendations')
    });

    return sections.toArray();
  }

  async getCountry() {
    let country = await this.report.get('country');
    return country ? country.name : '';
  }

  async getCommunity() {
    let community = await this.report.get('community');
    return community ? community.name : '';
  }

  async overviewFor(key) {
    let overviews = await this.report.get('overviews');
    let overview = overviews.findBy('key', key);
    return overview ? overview.get('body') : '';
  }
}
