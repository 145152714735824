import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import * as Sentry from '@sentry/browser';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Sentry.Integrations.Ember()],
    release: document.querySelector("meta[name='sentry:revision']").content,

    beforeSend(event, hint) {
      return hint.originalException.message === 'TransitionAborted' ? null : event;
    }
  });
}

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver
});

loadInitializers(App, config.modulePrefix);

export default App;
