import Sections from './-sections';
import moment from 'moment';
import InstitutionsSerializer from './institutions-serializer';
import PeopleSerializer from './people-serializer';

export default class MeetingSerializer {
  constructor(meeting) {
    this.meeting = meeting;
  }

  async serialize() {
    let sections = new Sections();

    sections.push('title', { value: this.meeting.name });
    sections.push('list', {
      title: 'Meeting Information',
      items: [
        { label: 'Pillars', value: this.meeting.pillars.join(', ') },
        { label: 'Date', value: moment(this.meeting.date).format('DD MMMM YYYY') },
        { label: 'Conducted By', value: await this.getConductedBy() }
      ]
    });

    sections.push('contacts', { title: 'Met With', contacts: await this.getMetWith() });
    sections.push('contacts', { title: 'Organisations', contacts: await this.getInstitutions() });

    sections.push('text', { title: 'Background', body: this.meeting.meetingBackground });
    sections.push('text', { title: 'Executive Summary', body: this.meeting.executiveSummary });
    sections.push('text', { title: 'Agreed Follow Up', body: this.meeting.agreedFollowUp });

    return sections.toArray();
  }

  async getConductedBy() {
    let conductedBy = await this.meeting.get('conductedBy');
    return conductedBy.mapBy('fullName').join(', ');
  }

  async getInstitutions() {
    let institutions = await this.meeting.get('institutions');
    return new InstitutionsSerializer(institutions).serialize();
  }

  async getMetWith() {
    let people = await this.meeting.get('metWith');
    return new PeopleSerializer(people).serialize();
  }
}
