import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  key: attr('string'),
  body: attr('string'),
  updatedBy: attr('', { readOnly: true }),
  updatedAt: attr('date'),

  // Relations
  report: belongsTo('report')
});
