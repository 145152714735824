import Component from '@ember/component';
import { computed } from '@ember/object';

const SUFIXES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const ICONS = {
  archive: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M20.5 21.5H24V25h-3.5v-3.5zM24 25h3.5v3.5H24V25zm-3.5 3.5H24V32h-3.5v-3.5zM24 32h3.5v3.5H24V32zm-3.5 3.5H24V39h-3.5v-3.5zM24 39h3.5v3.5H24V39z',
    fill: '#5E6C84'
  },
  audio: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M32.167 25.785v9.948A3.267 3.267 0 0 1 28.9 39h-.467a3.267 3.267 0 0 1-3.266-3.267v-.466A3.267 3.267 0 0 1 28.433 32h.467c.324 0 .638.047.933.135v-4.52l-7 .726V36.9a3.267 3.267 0 0 1-3.266 3.267H19.1a3.267 3.267 0 0 1-3.267-3.267v-.467a3.267 3.267 0 0 1 3.267-3.266h.467c.324 0 .637.047.933.135v-7.539c0-.444.362-.842.839-.89l9.989-1.035c.463-.048.839.274.839.717v1.23z',
    fill: '#FF5630'
  },
  document: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M14.667 39c0-.644.531-1.167 1.161-1.167h9.344c.641 0 1.161.518 1.161 1.167 0 .644-.531 1.167-1.161 1.167h-9.344A1.161 1.161 0 0 1 14.667 39zm0-4.667c0-.644.518-1.166 1.167-1.166h16.332c.645 0 1.167.518 1.167 1.166 0 .645-.518 1.167-1.167 1.167H15.834a1.164 1.164 0 0 1-1.167-1.167zm0-4.666c0-.645.518-1.167 1.167-1.167h16.332c.645 0 1.167.518 1.167 1.167 0 .644-.518 1.166-1.167 1.166H15.834a1.164 1.164 0 0 1-1.167-1.166zm0-4.667c0-.644.518-1.167 1.167-1.167h16.332c.645 0 1.167.518 1.167 1.167 0 .644-.518 1.167-1.167 1.167H15.834A1.164 1.164 0 0 1 14.667 25z',
    fill: '#2684FF'
  },
  excel: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M15.043 25.024l13.125-1.862a.438.438 0 0 1 .499.433v16.812a.438.438 0 0 1-.498.433l-13.125-1.814a.438.438 0 0 1-.377-.433V25.457c0-.217.16-.402.376-.433zm14.79 1.143h3a.5.5 0 0 1 .5.5v10.666a.5.5 0 0 1-.5.5h-3V26.167zm-11.748 9.916h1.857l1.788-2.785h.103l1.766 2.785h1.96l-2.666-4.113 2.705-4.107h-1.925l-1.732 2.877h-.102l-1.715-2.877h-2.022l2.609 4.079-2.626 4.141z',
    fill: '#007442'
  },
  generic: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M24 22.667h1.528a2 2 0 0 1 1.42.59l3.907 3.938a.5.5 0 0 1 .145.352v1.62a.5.5 0 0 1-.5.5H26a2 2 0 0 1-2-2V25h-4.667v14h9.334v-8.16H31V39a2.333 2.333 0 0 1-2.333 2.334h-9.334A2.333 2.333 0 0 1 17 39V25a2.333 2.333 0 0 1 2.333-2.333H24z',
    fill: '#5E6C84'
  },
  pdf: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M15.654 32.197v-1.804h1.014c.64 0 .953.295.953.9 0 .53-.102.904-1.403.904h-.564zm7.337 2.548h.776c.865 0 1.751-.257 1.751-2.165 0-1.923-.936-2.17-1.751-2.17h-.776v4.335zm-7.784 1.91h-1.26a.448.448 0 0 1-.447-.448v-7.26c0-.246.2-.447.447-.447h2.91c1.73 0 2.94 1.148 2.94 2.793 0 1.667-1.184 2.788-2.946 2.788h-1.197v2.126c0 .247-.2.448-.447.448zm8.692 0h-2.614a.448.448 0 0 1-.447-.448v-7.26c0-.246.2-.447.447-.447h2.614c2.459 0 3.813 1.443 3.813 4.065 0 2.637-1.354 4.09-3.813 4.09zm6.738 0h-1.26a.448.448 0 0 1-.448-.448v-7.26c0-.246.2-.447.447-.447h4.677c.246 0 .447.201.447.447v1.026a.447.447 0 0 1-.447.447h-2.969v1.33h2.674c.247 0 .447.2.447.447v1.011c0 .246-.2.447-.447.447h-2.674v2.552c0 .247-.2.448-.447.448z',
    fill: '#FF5630'
  },
  powerpoint: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M15.043 25.024l13.125-1.862a.438.438 0 0 1 .499.433v16.812a.438.438 0 0 1-.498.433l-13.125-1.814a.438.438 0 0 1-.377-.433V25.457c0-.217.16-.402.376-.433zm14.79 1.143h3a.5.5 0 0 1 .5.5v10.666a.5.5 0 0 1-.5.5h-3V26.167zm-10.67 1.696v8.22h1.721v-2.552h1.623c1.732 0 2.934-1.133 2.934-2.825 0-1.71-1.156-2.843-2.848-2.843h-3.43zm1.721 1.413h1.253c.991 0 1.555.501 1.555 1.435 0 .923-.575 1.425-1.56 1.425h-1.248v-2.86z',
    fill: '#CA5010'
  },
  presentation: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M17 34.333c.644 0 1.167.523 1.167 1.167V39a1.167 1.167 0 0 1-2.334 0v-3.5c0-.644.523-1.167 1.167-1.167zm9.333-7c.645 0 1.167.523 1.167 1.167V39a1.167 1.167 0 0 1-2.333 0V28.5c0-.644.522-1.167 1.166-1.167zM21.667 32c.644 0 1.166.522 1.166 1.167V39a1.167 1.167 0 1 1-2.333 0v-5.833c0-.645.522-1.167 1.167-1.167zM31 23.833c.644 0 1.167.523 1.167 1.167v14a1.167 1.167 0 0 1-2.334 0V25c0-.644.523-1.167 1.167-1.167z',
    fill: '#6554C0'
  },
  spreedsheet: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M15.833 23.833h3.5a1.167 1.167 0 0 1 0 2.334h-3.5a1.167 1.167 0 0 1 0-2.334zm0 4.667h3.5a1.167 1.167 0 0 1 0 2.333h-3.5a1.167 1.167 0 1 1 0-2.333zm0 4.667h3.5a1.167 1.167 0 1 1 0 2.333h-3.5a1.167 1.167 0 0 1 0-2.333zm0 4.666h3.5a1.167 1.167 0 0 1 0 2.334h-3.5a1.167 1.167 0 0 1 0-2.334zm8.167-14h8.167a1.167 1.167 0 0 1 0 2.334H24a1.167 1.167 0 0 1 0-2.334zm0 4.667h8.167a1.167 1.167 0 0 1 0 2.333H24a1.167 1.167 0 1 1 0-2.333zm0 4.667h8.167a1.167 1.167 0 1 1 0 2.333H24a1.167 1.167 0 0 1 0-2.333zm0 4.666h8.167a1.167 1.167 0 0 1 0 2.334H24a1.167 1.167 0 0 1 0-2.334z',
    fill: '#36B37E'
  },
  video: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M14.667 27.161c0-.549.443-.994.994-.994h9.678c.549 0 .994.443.994.994v9.678a.993.993 0 0 1-.994.994H15.66a.993.993 0 0 1-.994-.994V27.16zm17.686 9.434l-3.686-1.784v-5.62l3.686-1.786c.444-.215.98.078.98.533v8.124c0 .455-.536.748-.98.533z',
    fill: '#FF5630'
  },
  word: {
    d1: 'M4 .5h28.007a3.5 3.5 0 0 1 2.52 1.072l11.994 12.45a3.5 3.5 0 0 1 .979 2.429V60a3.5 3.5 0 0 1-3.5 3.5H4A3.5 3.5 0 0 1 .5 60V4A3.5 3.5 0 0 1 4 .5z',
    d2: 'M15.043 25.024l13.125-1.862a.438.438 0 0 1 .499.433v16.812a.438.438 0 0 1-.498.433l-13.125-1.814a.438.438 0 0 1-.377-.433V25.457c0-.217.16-.402.376-.433zm6.63 6.202h.09l1.556 5.44h1.612l2.182-8.22h-1.778l-1.27 5.794h-.091l-1.527-5.794h-1.453l-1.498 5.794h-.09l-1.283-5.794h-1.788l2.176 8.22h1.623l1.538-5.44zm8.16-5.06h3a.5.5 0 0 1 .5.5v10.667a.5.5 0 0 1-.5.5h-3V26.167z',
    fill: '#004E8C'
  }
};
const EXTENSION_MAP = {
  '7z': ICONS.archive,
  doc: ICONS.word,
  docx: ICONS.word,
  fods: ICONS.spreedsheet,
  fodt: ICONS.document,
  ods: ICONS.spreedsheet,
  odt: ICONS.document,
  pdf: ICONS.pdf,
  ppt: ICONS.powerpoint,
  pptx: ICONS.powerpoint,
  rar: ICONS.archive,
  txt: ICONS.document,
  xls: ICONS.excel,
  xlsx: ICONS.excel,
  zip: ICONS.archive
};

export default Component.extend({
  tagName: 'a',
  attributeBindings: ['document.name:download', 'document.location:href', 'target'],
  classNames: ['attachment', '-document'],
  target: '_blank',

  _icon: computed('document.{extension,type}', function () {
    let [type] = this.document.type.split('/');

    return ICONS[type] || EXTENSION_MAP[this.document.extension] || ICONS.generic;
  }),

  _size: computed('document.size', function () {
    return this._humanSize(this.document.size);
  }),

  _humanSize(bytes) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(1) + SUFIXES[i];
  }
});
