import Service, { inject as service } from '@ember/service';
import ENV from 'security/config/environment';

export default Service.extend({
  ajax: service('auth-ajax'),

  async fetchToken() {
    if (this._hasTokenExpired()) {
      await this._loadToken();
    }

    return this.token.access_token;
  },

  async loadEvents(startDateTime, endDateTime) {
    let token = await this.fetchToken();

    let response = await this.ajax.request(ENV.MS_CALENDAR_VIEW_URL, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        startDateTime,
        endDateTime,
        $top: 200
      }
    });

    return this._normalize(response);
  },

  async _loadToken() {
    this.token = await this.ajax.request('/api/v1/ms-graph/token');
    this.token.expiresAt = new Date();
    this.token.expiresAt.setTime(this.token.expiresAt.getTime() + this.token.expires_in * 1000 - 5 * 1000);

    return this.token;
  },

  _hasTokenExpired() {
    return !this.token || this.token.expiresAt < new Date();
  },

  _normalize({ value }) {
    return value.map((event) => {
      return {
        id: event.id,
        name: event.subject,
        url: event.webLink,
        startsAt: new Date(event.start.dateTime),
        endsAt: new Date(event.end.dateTime),
        calendar: 'office365'
      };
    });
  }
});
