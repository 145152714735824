import Component from '@ember/component';
import { computed } from '@ember/object';
import { bind } from '@ember/runloop';
import imgixURL from 'security/-helpers/imgix-url';

export default Component.extend({
  // Actions
  onclose: () => {},

  currentPhotoDownloadURL: computed('currentPhoto.path', function () {
    return imgixURL(this.currentPhoto.path, { dl: this.currentPhoto.name });
  }),

  nextPhoto() {
    this._switchPhoto(1);
  },

  prevPhoto() {
    this._switchPhoto(-1);
  },

  didInsertElement() {
    this._addKeyDownHandler();
  },

  willDestroyElement() {
    this._removeKeyDownHandler();
  },

  _addKeyDownHandler() {
    this._keyDownEventHandler = bind(this, this._onKeyDown);
    document.addEventListener('keydown', this._keyDownEventHandler);
  },

  _removeKeyDownHandler() {
    if (this._keyDownEventHandler) {
      document.removeEventListener('keydown', this._keyDownEventHandler);
      this._keyDownEventHandler = null;
    }
  },

  _onKeyDown(e) {
    switch (e.key) {
      case 'ArrowRight': this.nextPhoto(); break;
      case 'ArrowLeft': this.prevPhoto(); break;
      case 'Escape': this.onclose(); break;
    }
  },

  _switchPhoto(direction) {
    let currentIndex = this.photos.indexOf(this.currentPhoto);
    let nextPhoto = this.photos[currentIndex + direction];

    if (nextPhoto) {
      this.set('currentPhoto', nextPhoto);
    }
  }
});
