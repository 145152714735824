import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';
import ProjectStageSerializer from 'security/lib/project-stage-serializer';

export default Controller.extend({
  notify: service('satellite-notify'),
  router: service(),
  session: service(),
  store: service(),
  userNotification: service(),

  stageAttributes: alias('model'),
  project: alias('model.project'),

  title: computed('project.nextStage', function () {
    return 'Add ' + this.project.nextStage.replace('-', ' ');
  }),

  saveTask: task(function * (info) {
    this.stageAttributes.info = info;
    let stage = this.store.createRecord('project-stage', this.stageAttributes);

    try {
      yield stage.save();
      this.project.set('stage', this.project.nextStage);
      this._notifyUsers(this.project, stage);

      this.notify.success('Project stage was saved successfully.');
      this.router.transitionTo('projects.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  _notifyUsers(project, stage) {
    let ids = project.hasMany('notifyUsers').ids();

    return this.userNotification.notify(ids, () => [
      this._notificationBodySection(project),
      ...new ProjectStageSerializer(stage).serialize()
    ]);
  },

  _notificationBodySection(project) {
    let { firstName, lastName } = this.session.user;
    let path = this.router.urlFor('projects.show');
    let body = `
      <p>${firstName} ${lastName} added new stage to ${project.name}</p>
      <p>
        See stage details below or
        <a href="https://security.worldjewishcongress.org${path}">
          read and export in the Security Application
        </a>
      </p>
    `;

    return { type: 'text', attributes: { body } };
  }
});
