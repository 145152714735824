import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';

export default Mixin.create({
  notify: service('satellite-notify'),
  countryController: controller('country'),

  country: reads('countryController.model'),

  actions: {
    addInstitution(relatedTo, record) {
      if (isEmpty(record)) {
        return;
      }

      this.store.createRecord('institution', {
        country: this.get('country'),
        uuid: record.get('id'),
        name: record.get('name'),
        relatedTo: relatedTo
      }).save().then(() => {
        this.get('notify').success('Institution added successfully.');
      }, () => {
        this.get('notify').error('Something went wrong. Please, try again later.');
      });
    },

    removeInstitution(institution) {
      institution.destroyRecord().then(() => {
        this.get('notify').success('Institution removed successfully.');
      }, () => {
        this.get('notify').error('Something went wrong. Please, try again later.');
      });
    },

    updateOverview(key, value) {
      this.get('overview').set(key, value);

      this.get('overview').save().then(() => {
        this.get('notify').success('Overview was updated successfully.');
      }, () => {
        this.get('notify').error('Something went wrong. Please, try again later.');
      });
    }
  }
});
