import EmberRouter from '@ember/routing/router';
import $ from 'jquery';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL,

  _resetScrollBlacklist: Object.freeze([
    ['home.country.physical-infrastructure.show.index', 'home.country.physical-infrastructure.show.add-next-stage'],
    ['home.country.physical-infrastructure.show.index', 'home.country.physical-infrastructure.show.edit-stage']
  ]),

  didTransition() {
    this._super(...arguments);
    this._resetScroll();
    this._previousPath = this.currentPath;
  },

  _resetScroll() {
    if (!document) { return; }

    for (let i = 0; i < this._resetScrollBlacklist.length; i++) {
      let pair = this._resetScrollBlacklist[i];

      if (pair.indexOf(this.currentPath) > -1 && pair.indexOf(this._previousPath) > -1) {
        return;
      }
    }

    $('#country-page').trigger('reset-scroll');
  }
});

Router.map(function () {
  this.route('auth-callback');

  this.route('home', { path: '' }, function () {
    this.route('open', { path: 'open/:iso' });

    this.route('stats', { resetNamespace: true });

    this.route('meetings', { path: 'meetings-and-minutes', resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new', function () {
          this.route('pillars');
          this.route('info');
          this.route('notes');
          this.route('notify');
        });
      });

      this.route('show', { path: ':meeting_id' }, function () {
        this.route('edit');
        this.route('edit-note', { path: 'edit-node/:note_path' });
        this.route('notify');
      });
    });

    this.route('projects', { path: 'physical-infrastructure', resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new');
      });

      this.route('show', { path: ':project_id' }, function () {
        this.route('add-next-stage');
        this.route('edit');
        this.route('edit-stage', { path: 'edit-stage/:stage_id' });
      });
    });

    this.route('reports', { path: 'crisis-management', resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new');
      });

      this.route('show', { path: ':report_id' }, function () {
        this.route('edit');
        this.route('notify');
      });
    });

    this.route('visits', { path: 'visit-summary', resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new');
      });

      this.route('show', { path: ':visit_id' }, function () {
        this.route('edit');
        this.route('edit-note', { path: 'edit-note/:note_path' });
        this.route('notify');
      });
    });

    this.route('calendar', { resetNamespace: true });

    this.route('communities', { resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new');
        this.route('edit', { path: ':community_id' });
      });
    });

    this.route('venues', { resetNamespace: true }, function () {
      this.route('list', { path: '' }, function () {
        this.route('new');
        this.route('edit', { path: ':venue_id' });
      });
    });
  });

  this.route('not-found', { path: '/*dummy' });
});

export default Router;
