import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  router: service(),

  label: alias('model.label'),
  visit: alias('model.visit'),
  note: alias('model.note'),
  notePath: alias('model.notePath'),

  saveTask: task(function * () {
    this.visit.set(this.notePath, this.note);

    try {
      yield this.visit.save();

      this.notify.success('Visit summary was updated successfully.');
      this.router.transitionTo('visits.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }).drop()
});
