import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notify: service('satellite-notify'),

  classNames: ['box'],
  editMode: false,

  saveTask: task(function * (drillType) {
    this.get('report').set('drillType', drillType);

    try {
      yield this.get('report').save();
      this.get('notify').success('Activity type was saved successfully.');
      this.set('editMode', false);
    } catch (e) {
      this.get('notify').error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  actions: {
    edit() {
      this.setProperties({
        editMode: true
      });
    }
  }
});
