import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  notify: service('satellite-notify'),
  router: service(),
  store: service(),

  stage: alias('model'),

  title: computed('stage.kind', function () {
    return 'Edit ' + this.stage.kind.replace('-', ' ');
  }),

  saveTask: task(function * (info) {
    this.stage.set('info', info);

    try {
      yield this.stage.save();

      this.notify.success('Project stage was updated successfully.');
      this.router.transitionTo('projects.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
