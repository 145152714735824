import Component from '@ember/component';

export const CALENDARS_MAP = [
  { label: 'Physical Infrastructure', key: 'project' },
  { label: 'Meetings & Minutes', key: 'meeting' },
  { label: 'Crisis Report', key: 'report' },
  { label: 'Office 365', key: 'office365' }
];

export default Component.extend({
  classNames: ['calendar-selector', 'flex-row', 'flex-center'],

  _calendarsMap: CALENDARS_MAP,

  actions: {
    handleClick(calendar) {
      let calendars = this.calendars.split(',').filter((v) => v);

      if (calendars.indexOf(calendar) > -1) {
        calendars = calendars.filter((currentCalendar) => currentCalendar != calendar);
      } else {
        calendars = [...calendars, calendar].sort();
      }

      this.onchange(calendars.join(','));
    }
  }
});
