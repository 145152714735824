import Ember from 'ember';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';

const DEFAULT_DEBOUNCE_TIME = Ember.testing ? 0 : 200;

export default Component.extend({
  store: service(),

  debounceTime: DEFAULT_DEBOUNCE_TIME,
  label: 'Crisis Management Reports',
  allowClear: true,

  searchTask: task(function * (term) {
    yield timeout(this.debounceTime);

    return this.store.query('report', {
      sort: '-created-at',
      include: 'country',
      filter: { term }
    });
  }).restartable()
});
