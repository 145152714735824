import Component from '@ember/component';
import { computed } from '@ember/object';

const PILLARS = [
  'Political Advocacy',
  'Open Source Information',
  'Crisis Management',
  'Physical Infrastructure',
  'Application',
  'General'
];

export default Component.extend({
  _pillars: computed('selected.[]', function () {
    return PILLARS.map((pillar) => this._buildPillarOption(pillar));
  }),

  actions: {
    toggleAll() {
      if (this.selected.length !== PILLARS.length) {
        this.onchange(PILLARS.slice());
      } else {
        this.onchange([]);
      }
    }
  },

  _buildPillarOption(pillar) {
    let _this = this;

    return {
      label: pillar,

      get isChecked() {
        return _this.selected && _this.selected.indexOf(pillar) > -1;
      },

      set isChecked(value) {
        _this._togglePillar(pillar, value);
      }
    };
  },

  _togglePillar(passedPillar, isChecked) {
    let nextPillars = isChecked
      ? [...this.selected, passedPillar]
      : this.selected.filter((pillar) => pillar != passedPillar);

    this.onchange(nextPillars);
  }
});
