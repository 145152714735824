import Component from '@ember/component';

export default Component.extend({
  // Actions
  onsave: () => {},
  oncancel: () => {},

  init() {
    this._super(...arguments);
    this._cloneInfo();
  },

  actions: {
    save() {
      this.onsave(this._info);
    }
  },

  _cloneInfo() {
    this._info = Object.assign({}, this.stage.info);

    if (this._info.attachments) {
      this._info.attachments = this._info.attachments.slice();
    }
  }
});
