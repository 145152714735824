import Ember from 'ember';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { parameterize } from 'security/helpers/parameterize';
import VisitSerializer from 'security/lib/visit-serializer';

export default Controller.extend({
  logger: service(),
  router: service(),

  visit: alias('model'),

  formAction: computed('visit.name', function () {
    return `//wjc-exporter.herokuapp.com/fj/${parameterize(this.visit.name)}.docx`;
  }),

  exportTask: task(function* (dropdown) {
    let exportSections = yield new VisitSerializer(this.visit).serialize();

    let form = document.getElementById('export-form');
    let data = JSON.stringify(exportSections);
    form.children[0].value = data;

    dropdown.actions.close();

    if (!Ember.testing) {
      form.submit();
    }

    this.logger.log('visit-export', data);
  }).drop(),

  actions: {
    transitionToNotify(dropdown) {
      dropdown.actions.close();
      this.router.transitionTo('visits.show.notify');
    }
  }
});
