import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notify: service('satellite-notify'),

  classNames: ['box', 'flex-row', 'flex-center'],
  editMode: false,
  format: 'DD MMMM YYYY',

  saveTask: task(function * () {
    this.get('report').set('drillDate', this.get('drillDate'));

    try {
      yield this.get('report').save();
      this.get('notify').success('Activity date was saved successfully.');
      this.set('editMode', false);
    } catch (e) {
      this.get('notify').error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  actions: {
    edit() {
      this.setProperties({
        editMode: true,
        drillDate: this.get('report.drillDate')
      });
    },

    cancel() {
      this.set('editMode', false);
    }
  }
});
