import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return {
      report: this.modelFor('reports.show').report,
      users: []
    };
  }
});
