import Route from '@ember/routing/route';

export default Route.extend({
  model({ meeting_id }) {
    return this.store.findRecord('meeting', meeting_id, { backgroundReload: true });
  },

  afterModel(meeting) {
    this.set('title', meeting.name);
  }
});
