import Component from '@ember/component';

export default Component.extend({
  // Actions
  onsave: () => {},
  oncancel: () => {},

  init() {
    this._super(...arguments);

    this.model = {
      country: this.venue.country,
      community: this.venue.community,
      name: this.venue.name,
      street: this.venue.street,
      city: this.venue.city,
      postalCode: this.venue.postalCode
    };
  }
});
