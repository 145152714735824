import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    let project = this.modelFor('projects.show');

    return {
      project,
      kind: project.nextStage,
      info: this._getDefaultInfo(project.nextStage)
    };
  },

  _getDefaultInfo(kind) {
    let defaultInfo = {};

    if (['audit', 'grant-request', 'grant-agreement', 'completion'].indexOf(kind) > -1) {
      defaultInfo.date = new Date();
    } else if (kind === 'recommendations') {
      defaultInfo.sentAt = new Date();
    }

    return defaultInfo;
  }
});
