import Sections from './-sections';
import asyncEach from './-async-each';

export default class InstitutionsSerializer {
  constructor(institutions) {
    this.institutions = institutions;
  }

  async serialize() {
    let sections = new Sections();

    await asyncEach(this.institutions, async (institution) => {
      let attrs = { name: institution.name, fields: [] };
      let headquarter = await institution.get('primaryHeadquarter');

      if (headquarter) {
        attrs.fields = [
          { label: 'Address', value: headquarter.address1 },
          { label: 'City', value: headquarter.city },
          { label: 'Postal Code', value: headquarter.postalCode },
          { label: 'Country', value: headquarter.country }
        ];
      }

      sections.push('contact', attrs);
    });

    return sections.toArray();
  }
}
