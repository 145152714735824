import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['search-item'],

  modelName: reads('item.searchable._internalModel.modelName'),
  componentName: computed('modelName', function () {
    return `search-item/${this.get('modelName')}`;
  })
});
