import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  name: attr('string'),
  city: attr('string'),
  startDate: attr('datepicker'),
  endDate: attr('datepicker'),
  brief: attr('string'),
  followUp: attr('string'),
  graphUrl: attr('string', { readOnly: true }),

  createdAt: attr('date', { readOnly: true }),
  createdBy: attr('', { readOnly: true }),
  updatedAt: attr('date', { readOnly: true }),
  updatedBy: attr('', { readOnly: true }),

  // Relations
  country: belongsTo('country'),
  meetings: hasMany('meeting'),
  projects: hasMany('project'),
  reports: hasMany('report'),
  travellers: hasMany('shuttle-person', { asArrayAttr: true })
});
