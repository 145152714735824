import Ember from 'ember';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { alias, sort, filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';
import ProjectSerializer from 'security/lib/project-serializer';
import { parameterize } from 'security/helpers/parameterize';

export default Controller.extend({
  logger: service(),
  notify: service('satellite-notify'),

  project: alias('model'),

  stagesSorting: Object.freeze(['createdAt:asc']),
  persistedStages: filterBy('project.stages', 'isNew', false),
  stages: sort('persistedStages', 'stagesSorting'),
  currentStage: alias('stages.lastObject'),

  formAction: computed('project.name', function () {
    return `//wjc-exporter.herokuapp.com/fj/${parameterize(this.project.name)}.docx`;
  }),

  exportTask: task(function * () {
    let exportSections = yield new ProjectSerializer(this.project).serialize();

    let form = document.getElementById('export-form');
    let data = JSON.stringify(exportSections);
    form.children[0].value = data;

    if (!Ember.testing) {
      form.submit();
    }

    this.logger.log('project-export', data);
  }).drop(),

  completeStageTask: task(function * () {
    this.currentStage.set('isCompleted', true);

    try {
      yield this.currentStage.save();
      this.notify.success('Stage was marked as completed successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  completeProjectStage: task(function * () {
    this.project.set('isCompleted', true);

    try {
      yield this.project.save();
      this.notify.success('Project was marked as completed successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
