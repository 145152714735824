import Route from '@ember/routing/route';

const LABELS = {
  brief: 'Brief',
  followUp: 'Follow-Up'
};

export default Route.extend({
  async model({ note_path }) {
    let visit = this.modelFor('visits.show');

    return {
      visit,
      label: LABELS[note_path],
      note: visit.get(note_path),
      notePath: note_path
    };
  }
});
