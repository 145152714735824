import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { notEmpty, reads } from '@ember/object/computed';

export default Controller.extend({
  queryParams: ['page', 'term', 'type'],
  page: 1,
  term: '',
  type: 'all',

  // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
  recentlyUpdatedSorting: ['updatedAt:desc'],
  recentlyUpdatedSorted: sort('model.home', 'recentlyUpdatedSorting'),

  recentlyUpdated: computed('recentlyUpdatedSorted.[]', function () {
    return this.get('recentlyUpdatedSorted').slice(0, 10);
  }),

  isSearching: notEmpty('term'),
  searchResults: reads('model.searchResults'),
  searchDebounceTime: computed('isSearching', function () {
    return this.get('isSearching') ? 200 : 400;
  }),

  actions: {
    handleSearch(term) {
      this.setProperties({
        term,
        page: 1,
        isLoading: true
      });
    }
  }
});
