import Sections from './-sections';
import { toDateRange } from 'security/helpers/to-date-range';
import PeopleSerializer from './people-serializer';
import asyncEach from './-async-each';
import moment from 'moment';

export default class VisitSerializer {
  constructor(visit) {
    this.visit = visit;
  }

  async serialize() {
    let sections = new Sections();

    sections.push('title', { value: this.visit.name });
    sections.push('list', {
      title: 'Visit Summary Information',
      items: [
        { label: 'Country', value: await this.getCountry() },
        { label: 'City', value: this.visit.city },
        { label: 'Date of Report', value: moment(new Date()).format('DD MMMM YYYY') },
        { label: 'Date of Visit', value: toDateRange(this.visit.startDate, this.visit.endDate) }
      ]
    });

    sections.push('contacts', { title: 'Who Travelled', contacts: await this.getTravellers() });

    sections.push('text', { title: 'Visit Brief', body: this.visit.brief });
    sections.push('text', { title: 'Visit Follow-Up', body: this.visit.followUp });

    sections.push('title', { value: 'Related Activities', level: 2 });
    await this.addProjects(sections);
    await this.addMeetings(sections);
    await this.addReports(sections);

    return sections.toArray();
  }

  async getCountry() {
    let country = await this.visit.get('country');
    return country ? country.name : '';
  }

  async getTravellers() {
    let travellers = await this.visit.get('travellers');
    return new PeopleSerializer(travellers).serialize();
  }

  async getMetWith() {
    let metWith = await this.visit.get('metWith');
    return new PeopleSerializer(metWith).serialize();
  }

  async addProjects(sections) {
    let projects = await this.visit.get('projects');

    if (projects.length === 0) { return; }

    let items = [];

    await asyncEach(projects, async (project) => {
      let venue = await project.get('venue');
      let community = await venue.get('community');

      items.push(`${venue.name}, ${community.name}`);
    });

    sections.push('list', {
      title: '',
      items: items.map((value) => ({ label: 'Name', value }))
    });
  }

  async addMeetings(sections) {
    let meetings = await this.visit.get('meetings');

    await asyncEach(meetings, async (meeting) => {
      let metWith = await meeting.get('metWith');
      let contacts = await new PeopleSerializer(metWith).serialize();

      sections.push('list', {
        title: meeting.name,
        items: [
          { label: 'Date of Report', value: moment(meeting.date).format('DD MMMM YYYY') }
        ]
      });

      sections.push('contacts', { title: 'Met With', contacts });
      sections.push('text', { title: 'Executive Summary', body: meeting.executiveSummary });
    });
  }

  async addReports(sections) {
    let reports = await this.visit.get('reports');

    await asyncEach(reports, async (report) => {
      let overviews = await report.get('overviews');
      let overview = overviews.findBy('key', 'executive-summary');
      let overviewBody = overview ? overview.get('body') : '';

      sections.push('list', {
        title: report.displayName,
        items: [
          { label: 'Date of Visit', value: moment(report.date).format('DD MMMM YYYY') }
        ]
      });

      sections.push('text', { title: 'Overview', body: overviewBody });
    });
  }
}
