import Component from '@ember/component';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment';

export default Component.extend({
  store: service(),
  msGraph: service(),

  isLoading: or('_loadEventsTask.isRunning', '_loadMSGraphEventsTask.isRunning'),

  events: computed('_loadEventsTask.lastComplete.value', '_loadMSGraphEventsTask.lastComplete.value', function () {
    return [this._loadEventsTask, this._loadMSGraphEventsTask].reduce((acc, task) => {
      if (task.lastComplete && task.lastComplete.value) {
        let events = task.lastComplete.value;

        Object.keys(task.lastComplete.value).forEach((key) => {
          acc[key] = acc[key] ? acc[key].concat(events[key]) : events[key];
        });
      }

      return acc;
    }, {});
  }),

  weeks: computed('month', function () {
    let today = new Date().toDateString();
    let currentDate = new Date(this.month);
    let currentMonth = currentDate.getMonth();
    let day = moment(currentDate).startOf('month').startOf('week');
    let lastDay = moment(currentDate).endOf('month').endOf('week');

    let weeks = [];
    let currentWeek = { id: day.format('YYYY-MM'), data: [] };

    while (day <= lastDay) {
      let key = day.format('YYYY-MM-DD');
      currentWeek.data.push({
        id: key,
        day: day.date(),
        month: day.format('MMM'),
        isToday: day.toDate().toDateString() === today,
        isCurrentMonth: day.month() === currentMonth
      });

      day.add(1, 'day');

      if (currentWeek.data.length === 7) {
        weeks.push(currentWeek);
        currentWeek = { id: day.format('YYYY-MM'), data: [] };
      }
    }

    return weeks;
  }),

  didReceiveAttrs() {
    let date = [this.month.getFullYear(), this.month.getMonth(), this.month.getDate()];
    let startsAt = moment.utc(date).startOf('month').startOf('week').toISOString();
    let endsAt = moment.utc(date).endOf('month').endOf('week').toISOString();

    this._loadEventsTask.perform(startsAt, endsAt, this.calendars);
    this._loadMSGraphEventsTask.perform(startsAt, endsAt, this.calendars);
  },

  _loadEventsTask: task(function* (startsAt, endsAt, calendars) {
    if (!this._hasAPICalendar(calendars)) {
      return {};
    }

    let events = yield this.store.query('calendarEvent', {
      filter: {
        'starts-at': startsAt,
        'ends-at': endsAt,
        calendar: calendars
      },
      include: 'eventable',
      sort: 'starts-at'
    });

    return this._groupEventsByDay(events);
  }).restartable(),

  _loadMSGraphEventsTask: task(function* (startsAt, endsAt, calendars) {
    if (calendars.indexOf('office365') < 0) {
      return {};
    }

    let events = yield this.msGraph.loadEvents(startsAt, endsAt);
    return this._groupEventsByDay(events);
  }).restartable(),

  _groupEventsByDay(events) {
    return events.reduce((acc, event) => {
      let key = moment(event.startsAt).format('YYYY-MM-DD');

      if (!acc[key]) { acc[key] = []; }

      acc[key].push(event);

      return acc;
    }, {});
  },

  _hasAPICalendar(calendars) {
    return ['meeting', 'report', 'project'].filter((calendar) => calendars.indexOf(calendar) > -1).length > 0;
  }
});
