import Route from '@ember/routing/route';

export default Route.extend({
  async model() {
    let visit = this.modelFor('visits.show');

    await visit.hasMany('travellers').load();

    return visit;
  }
});
