import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Model.extend({
  // Attributes
  name: attr('string'),
  street: attr('string'),
  city: attr('string'),
  postalCode: attr('string'),

  createdBy: attr('', { readOnly: true }),
  createdAt: attr('date'),
  updatedBy: attr('', { readOnly: true }),
  updatedAt: attr('date'),

  // Relations
  country: belongsTo('country'),
  community: belongsTo('community'),
  projects: hasMany('project'),

  address: computed('street', 'city', 'postalCode', function () {
    return [
      this.street,
      this.city,
      this.postalCode
    ].reject(isEmpty).join(', ');
  })
});
