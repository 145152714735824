import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { run, bind, throttle, scheduleOnce } from '@ember/runloop';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),

  isPickerVisible: false,

  searchTask: task(function * (term) {
    let data = [];
    let filter = { term, type: 'country' };

    this.set('term', term);

    if (term) {
      data = yield this.store.query('search-document', {
        filter,
        page: { number: 1, size: 10 },
        include: 'searchable'
      });
    }

    yield this.set('data', data);
  }).restartable(),

  didInsertElement() {
    scheduleOnce('afterRender', this, this._attachShortcutHandler);
  },

  willDestroyElement() {
    run(() => this._detachShortcutHandler());
  },

  _attachShortcutHandler() {
    this._shortcutHandler = bind(this, this._onKeyDown);
    document.addEventListener('keydown', this._shortcutHandler);
  },

  _detachShortcutHandler() {
    if (this._shortcutHandler) {
      document.removeEventListener('keydown', this._shortcutHandler);
      this._shortcutHandler = null;
    }
  },

  _onKeyDown(e) {
    throttle(this, () => {
      if (e.metaKey && e.key === 'k') {
        this.toggleProperty('isPickerVisible');
      } else if (e.key === 'Escape') {
        this.set('isPickerVisible', false);
      }
    }, 300);
  }
});
