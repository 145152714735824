import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { hash } from 'rsvp';
import forbiddenErrorHandler from 'security/-helpers/forbidden-error-handler';

export default Route.extend({
  queryParams: {
    page: { refreshModel: true },
    term: { refreshModel: true },
    type: { refreshModel: true }
  },

  dialog: service('satellite-dialog'),
  recentlyViewed: service('recently-viewed'),

  model(params) {
    return hash({
      // TODO: Super bad! 🤭
      home: this.store.findAll('country'),
      searchResults: this._getSearchResults(params)
    }).catch(forbiddenErrorHandler(this.dialog));
  },

  setupController(controller) {
    this._super(...arguments);

    controller.setProperties({
      isLoading: false,
      recentlyViewed: this.get('recentlyViewed').retrieve()
    });
  },

  _getSearchResults({ page, term, type }) {
    if (isEmpty(term)) { return []; }

    let filter = {};

    if (isPresent(term)) {
      filter.term = term;
    }

    if (isPresent(type) && type !== 'all') {
      filter.type = type;
    }

    return this.store.query('search-document', {
      filter: filter,
      page: { number: page, size: 10 },
      include: 'searchable'
    });
  }
});
