import Component from '@ember/component';
import { VENUE_TYPES, CATEGORIES_COLLECTION } from 'security/models/project';

export default Component.extend({
  categories: Object.freeze(CATEGORIES_COLLECTION),
  venueTypes: Object.freeze(VENUE_TYPES),

  // Actions
  onsave: () => {},
  oncancel: () => {},

  init() {
    this._super(...arguments);

    this.model = {
      venue: this.project.venue,
      category: this.project.category,
      venueTypes: this.project.venueTypes.slice(),
      description: this.project.description,
      notifyUsers: (this.project.notifyUsers || []).slice()
    };
  }
});
