import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';

export default Model.extend({

  // Attributes
  key:       attr('string'),
  body:      attr('string'),
  updatedAt: attr('date'),
  updatedBy: attr('', { readOnly: true }),

  // Relations
  country:   belongsTo('country'),
  histories: hasMany('history')
});
