import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNameBindings: [':timeline-section', 'type'],

  // Actions
  onaddnextstage: () => {},
  oncompletestage: () => {},
  oncompleteproject: () => {},

  type: computed('project.isCompleted', function () {
    return this.project.isCompleted ? '-info -last' : '-action';
  }),

  currentStageLabel: computed('project.stage', function () {
    return this.project.stage.replace('-', ' ');
  }),

  nextStageLabel: computed('project.nextStage', function () {
    return this.project.nextStage.replace('-', ' ');
  })
});
