import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  name: attr('string'),

  createdAt: attr('date', { readOnly: true }),
  createdBy: attr('', { readOnly: true }),
  updatedAt: attr('date', { readOnly: true }),
  updatedBy: attr('', { readOnly: true }),

  // Relations
  country: belongsTo('country'),
  venues: hasMany('venue')
});
