import Controller from '@ember/controller';
import { set } from '@ember/object';

export default Controller.extend({
  actions: {
    setCategory(category) {
      set(this.model, 'category', category);
      this.transitionToRoute('meetings.list.new.pillars');
    }
  }
});
