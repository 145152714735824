import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Component.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  store: service(),

  // Actions
  onchange: () => {},

  async init() {
    this._super(...arguments);

    if (this.selected) {
      let community = await this.selected.get('community');
      let country = await community.get('country');

      this.setProperties({ country, community });
    }
  },

  createCommunityTask: task(function * (name) {
    let community = this.store.createRecord('community', { name, country: this.country });

    try {
      yield community.save();

      this.send('handleCommunityChange', community);
      this.notify.success('Community was created successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  createVenueTask: task(function* (name) {
    let venue = this.store.createRecord('venue', { name, community: this.community });

    try {
      yield venue.save();

      this.onchange(venue);
      this.notify.success('Venue was created successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  actions: {
    handleCountryChange(country) {
      this.setProperties({ country, community: null });
      this.onchange(null);
    },

    handleCommunityChange(community) {
      this.set('community', community);
      this.onchange(null);
    },

    promptCommunityCreation(name) {
      this.dialog.confirm({
        title: 'Are you sure?',
        message: `Are you sure you want to create a new community called "${name}"?`
      }).then(() => {
        this.createCommunityTask.perform(name);
      });
    },

    promptVenueCreation(name) {
      this.dialog.confirm({
        title: 'Are you sure?',
        message: `Are you sure you want to create a new venue called "${name}"?`
      }).then(() => {
        this.createVenueTask.perform(name);
      });
    }
  }
});
