import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return {
      visit: this.modelFor('visits.show'),
      users: []
    };
  }
});
