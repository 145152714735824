import Ember from 'ember';
import RSVP from 'rsvp';
import ENV from 'security/config/environment';

const noAccessMessage = {
  title: 'Access Denied',
  message: `
    Looks like you don’t have access to this application.
    <br>
    For more information, please contact
    <a href="mailto:mmeldrum@wjcmail.org">mmeldrum@wjcmail.org.</a>
  `,
  confirmLabel: 'Back to My Profile',
  cancelLabel: false
};

const _redirectToProfile = function () {
  if (!Ember.testing && window) {
    window.location = ENV.AUTH_HOST;
  }
};

export default function (dialog) {
  return ({ errors }) => {
    if (errors[0].status === '403') {
      dialog.confirm(noAccessMessage).then(_redirectToProfile, _redirectToProfile);

      return [];
    } else {
      return RSVP.reject(...arguments);
    }
  };
}
