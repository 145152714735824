export default class Sections {
  constructor() {
    this.sections = [];
  }

  push(type, attributes) {
    this.sections.push({ type, attributes });

    return this;
  }

  concat(array) {
    this.sections = this.sections.concat(array);

    return this;
  }

  toArray() {
    return this.sections;
  }
}
