import Component from '@ember/component';
import { computed } from '@ember/object';
import { Promise } from 'rsvp';
import { task } from 'ember-concurrency';
import InViewportMixin from 'ember-in-viewport';
import imgixURL from 'security/-helpers/imgix-url';

const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export default Component.extend(InViewportMixin, {
  tagName: 'img',
  classNameBindings: [':imgix-img', '_loadImageTask.last.isFinished:-loaded:-loading'],

  attributeBindings: [
    '_alt:alt',
    'crossorigin',
    '_src:src',
    '_height:height',
    '_width:width'
  ],

  // Public attributes
  alt: null,
  crop: null,
  crossorigin: 'anonymous',
  fit: 'crop',
  options: Object.freeze({}),
  photo: null,
  height: null,
  width: null,

  init() {
    this._super(...arguments);
    this.set('_src', this._getImgixPlaceholderSrc());
  },

  didEnterViewport() {
    if (this._src === this._getImgixPlaceholderSrc()) {
      this._loadImageTask.perform();
    }
  },

  didUpdateAttrs() {
    this.set('_src', this._getImgixPlaceholderSrc());
    this._loadImageTask.perform();
  },

  willDestroyElement() {
    this._resetLoadingImage();
  },

  _alt: computed('alt', 'photo', function () {
    return this.alt || this.photo.path.split('/').pop();
  }),

  _height: computed('photo', 'height', 'width', function () {
    return this.height || (this.width && this.width / this._getAspectRatio());
  }),

  _width: computed('photo', 'height', 'width', function () {
    return this.width || (this.height && this.height * this._getAspectRatio());
  }),

  _getAspectRatio() {
    return this.photo.meta.width / this.photo.meta.height;
  },

  _getDPR() {
    return (window.devicePixelRatio || 1).toFixed(2);
  },

  _getImgixSrc(overwrites = {}) {
    return imgixURL(this.photo.path, {
      ...this.options,
      crop: this.crop,
      fit: this.fit,
      h: this.height,
      w: this.width,
      dpr: this._getDPR(),
      ...overwrites
    });
  },

  _getImgixPlaceholderSrc() {
    return this._getImgixSrc({ w: 32, h: null, blur: 64 });
  },

  _loadImageTask: task(function * () {
    let src = this._getImgixSrc();
    let loadedSrc = yield this._loadImage(src);

    this.set('_src', loadedSrc);
  }).restartable(),

  _loadImage(src) {
    this._resetLoadingImage();

    return new Promise((resolve) => {
      this._loadingImage = new Image();
      this._loadImageCallback = () => resolve(src);

      this._loadingImage.addEventListener('load', this._loadImageCallback, true);
      this._loadingImage.addEventListener('error', this._loadImageCallback, true);
      this._loadingImage.crossOrigin = 'Anonymous';
      this._loadingImage.src = src;

      // Resolve with the cached image if present
      if (this._loadingImage.complete || this._loadingImage.readyState === 4) {
        resolve(src);
      }
    });
  },

  _resetLoadingImage() {
    if (this._loadingImage) {
      this._loadingImage.removeEventListener('load', this._loadImageCallback, true);
      this._loadingImage.removeEventListener('error', this._loadImageCallback, true);
      this._loadingImage.src = BLANK_IMAGE;

      this._loadingImage = null;
      this._loadImageCallback = null;
    }
  }
});
