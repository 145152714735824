import Route from '@ember/routing/route';

const LABELS = {
  meetingBackground: 'Meeting Background',
  executiveSummary: 'Executive Summary',
  agreedFollowUp: 'Agreed Follow-Up'
};

export default Route.extend({
  async model({ note_path }) {
    let meeting = this.modelFor('meetings.show');

    return {
      meeting,
      label: LABELS[note_path],
      note: meeting.get(note_path),
      notePath: note_path
    };
  }
});
