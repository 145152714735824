import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { Promise, all } from 'rsvp';
import S3Uploader from 'ember-uploader/uploaders/s3';
import isImage from 'security/-helpers/is-image';

export default Component.extend({
  notify: service('satellite-notify'),
  session: service(),

  // Actions
  onchange: () => {},
  onuploadstart: () => {},
  onuploadend: () => {},

  signingUrl: '/api/v1/uploads/sign',
  multiple: true,
  disabled: false,

  _disabled: or('uploadTask.isRunning', 'disabled'),

  _attachments: computed('attachments.[]', function () {
    return this.attachments || [];
  }),

  _documents: computed('attachments.[]', function () {
    return this._attachments.filter((attachment) => !isImage(attachment));
  }),

  _photos: computed('attachments.[]', function () {
    return this._attachments.filter((attachment) => isImage(attachment));
  }),

  _uploadObserver: observer('uploadTask.isRunning', function () {
    if (!this.uploadTask.isRunning) {
      this.onuploadend();
      this._uploadStartTriggered = false;
    } else if (!this._uploadStartTriggered) {
      this.onuploadstart();
      this._uploadStartTriggered = true;
    }
  }),

  uploadingCount: computed('uploadTask.{numQueued,numRunning}', function () {
    return this.uploadTask.numQueued + this.uploadTask.numRunning;
  }),

  uploadTask: task(function * (file) {
    // Do this in parallel
    let [response, meta] = yield all([
      this._createUploader().upload(file),
      this._getMeta(file)
    ]);

    let { size, name, type } = file;
    let extension = file.name.split('.').pop();
    let path = response.querySelector('Key').textContent;
    let location = decodeURIComponent(response.querySelector('Location').textContent);

    this.onchange([...this._attachments, { name, location, path, extension, size, type, meta }]);
  }).maxConcurrency(3).enqueue(),

  enqueueForUpload({ target: { files }}) {
    for (let i = 0; i < files.length; i++) {
      this.uploadTask.perform(files[i]);
    }
  },

  removeAttachment(attachment) {
    let attachments = this._attachments.filter((item) => item !== attachment);
    this.onchange(attachments);
  },

  _createUploader() {
    return S3Uploader.create({
      signingAjaxSettings: { headers: this.session.requestHeaders() },
      signingUrl: this.signingUrl
    });
  },

  _getMeta(file) {
    return isImage(file) ? this._getImageDimensions(file) : {};
  },

  _getImageDimensions(file) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      let image = new Image();

      reader.onload = () => image.src = reader.result;
      image.onload = () => resolve({ width: image.width, height: image.height });

      reader.readAsDataURL(file);
    });
  }
});
