import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  widget: service('shuttle-widget'),

  classNames: ['contact-list'],

  labelPath: computed('type', function () {
    return this.type === 'person' ? 'fullName' : 'name';
  }),

  actions: {
    open(contact) {
      this.widget.open(this.type, {
        personId: contact.id,
        institutionId: contact.id
      });
    }
  }
});
