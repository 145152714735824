import Ember from 'ember';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';

const DEFAULT_DEBOUNCE_TIME = Ember.testing ? 0 : 200;

export default Component.extend({
  store: service(),

  debounceTime: DEFAULT_DEBOUNCE_TIME,
  label: 'Users',

  searchTask: task(function * (term) {
    yield timeout(this.debounceTime);

    return this.store.query('user', {
      sort: 'first-name,last-name',
      filter: { term }
    });
  }).restartable(),

  actions: {
    handleFocusEvent(value) {
      if (this.get('componentName') === 'power-select-multiple') {
        this.set('isFocused', value);
      }
    }
  }
});
