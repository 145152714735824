import Component from '@ember/component';

export default Component.extend({
  // Actions
  onsave: () => {},
  oncancel: () => {},

  init() {
    this._super(...arguments);

    this.model = {
      name: this.community.name,
      country: this.community.country
    };
  },

  actions: {
    save() {
      return this.onsave(this.model);
    }
  }
});
