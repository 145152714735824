import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { computed } from '@ember/object';

export default Model.extend({
  // Attributes
  firstName: attr('string'),
  lastName: attr('string'),
  email: attr('string'),
  avatar: attr('string'),

  fullName: computed('firstName', 'lastName', function () {
    return [this.firstName, this.lastName].filter((v) => v != null).join(' ');
  })
});
