import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  notify: service('satellite-notify'),
  store: service(),
  router: service(),

  saveTask: task(function * (attrs) {
    let venue = this.store.createRecord('venue', attrs);

    try {
      yield venue.save();
      this.notify.success('Venue was created successfully.');
      this.router.transitionTo('venues');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
