import { camelize } from '@ember/string';
import { isArray, A } from '@ember/array';
import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize(serialized) {
    if (isArray(serialized)) {
      let transformed = this._camelizeKeys(serialized);
      return A(transformed);
    } else {
      return A([]);
    }
  },

  serialize(deserialized) {
    return deserialized;
  },

  _camelizeKeys(data) {
    return data.map(function (item) {
      let result = item;

      if (typeof item === 'object') {
        result = {};

        Object.keys(item).forEach(function (key) {
          result[camelize(key)] = item[key];
        });
      }

      return result;
    });
  }
});
