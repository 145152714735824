import Component from '@ember/component';
import { set, setProperties, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

const DRILL_TYPES = [
  { group: 'Exercise', label: 'Full scale exercise', isChecked: false },
  { group: 'Exercise', label: 'Table top exercise', isChecked: false },
  { group: 'Exercise', label: 'Function exercise', isChecked: false },
  { group: 'Training', label: 'Training for professional team', isChecked: false },
  { group: 'Training', label: 'Training for decision makers', isChecked: false },
  { group: 'Training', label: 'Training for all team', isChecked: false },
  { group: 'Training', label: 'Training for school', isChecked: false },
  { group: 'Training', label: 'Magen David Adom Training', isChecked: false },
  { group: 'Training', label: 'Safe school training ', isChecked: false },
  { group: 'Consultation', label: 'ABC', description: '(Assessment/Briefing/Consultation)', isChecked: false },
  { group: 'Other', label: 'General', isChecked: false }
];

export default Component.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  store: service(),

  // Actions
  onsave: () => {},
  oncancel: () => {},

  init() {
    this._super(...arguments);

    this.model = {
      country: this.report.country,
      community: this.report.community,
      date: this.report.date,
      drillType: this.report.drillType
    };

    let selectedDrillTypes = (this.report.drillType || '').split(', ');

    let drillTypes = DRILL_TYPES.map((drillType) => {
      return Object.assign({}, drillType, { isChecked: selectedDrillTypes.indexOf(drillType.label) > -1 });
    });

    this.setProperties({
      drillTypes
    });
  },

  groupedDrillTypes: computed('drillTypes.[]', function () {
    return this.drillTypes.reduce((acc, drillType) => {
      if (!acc[drillType.group]) {
        acc[drillType.group] = [];
      }

      acc[drillType.group].push(drillType);
      return acc;
    }, {});
  }),

  selectedDrillTypes: computed('drillTypes.@each.isChecked', function () {
    return this.drillTypes.filterBy('isChecked').mapBy('label');
  }),

  createCommunityTask: task(function* (name) {
    let community = this.store.createRecord('community', { name, country: this.model.country });

    try {
      yield community.save();
      set(this.model, 'community', community);

      this.notify.success('Community was created successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  actions: {
    save() {
      setProperties(this.model, { drillType: this.selectedDrillTypes.join(', ') });
      return this.onsave(this.model);
    },

    handleCountryChange(country) {
      setProperties(this.model, { country, community: null });
    },

    promptCommunityCreation(name) {
      this.dialog.confirm({
        title: 'Are you sure?',
        message: `Are you sure you want to create a new community called "${name}"?`
      }).then(() => {
        this.createCommunityTask.perform(name);
      });
    }
  }
});
