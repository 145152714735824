import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  store: service(),
  router: service(),

  community: alias('model'),

  saveTask: task(function * (attrs) {
    this.community.setProperties(attrs);

    try {
      yield this.community.save();
      this.notify.success('Community was updated successfully.');
      this.router.transitionTo('communities');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  deleteTask: task(function * () {
    try { yield this.dialog.confirmDelete(); } catch (e) { return; }

    try {
      yield this.community.destroyRecord();
      this.notify.success('Community was deleted successfully.');
      this.router.transitionTo('communities');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
