import Ember from 'ember';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import ReportSerializer from 'security/lib/report-serializer';
import { parameterize } from 'security/helpers/parameterize';

export default Controller.extend({
  logger: service(),
  router: service(),
  notify: service('satellite-notify'),

  queryParams: ['tab'],
  tab: 'quick-overview',

  report: alias('model.report'),
  country: alias('report.country'),
  overviews: alias('report.overviews'),
  prevReport: alias('model.prevReport'),

  formAction: computed('report.displayName', function () {
    return `//wjc-exporter.herokuapp.com/fj/${parameterize(this.report.displayName)}.docx`;
  }),

  exportTask: task(function* (dropdown) {
    let report = this.report;
    let exportSections = yield new ReportSerializer(report).serialize();

    let form = document.getElementById('export-form');
    let data = JSON.stringify(exportSections);
    form.children[0].value = data;

    dropdown.actions.close();

    if (!Ember.testing) {
      form.submit();
    }

    this.logger.log('report-export', data);
  }).drop(),

  actions: {
    updatePreparednessLevel(level) {
      this.report.set('preparednessLevel', level);

      this.report.save().then(() => {
        this.get('notify').success('Level of preparedness was updated successfully.');
      });
    },
    transitionToNotify(dropdown) {
      dropdown.actions.close();
      this.router.transitionTo('reports.show.notify');
    }
  }
});
