import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  name: attr('string'),
  category: attr('string'),
  pillars: attr(), // array
  date: attr('datepicker'),
  meetingBackground: attr('string'),
  executiveSummary: attr('string'),
  agreedFollowUp: attr('string'),
  numberOfParticipants: attr('number'),

  createdBy: attr('', { readOnly: true }),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  // Relations
  country: belongsTo('country'),
  conductedBy: hasMany('shuttle-person', { asArrayAttr: true }),
  institutions: hasMany('shuttle-institution', { asArrayAttr: true }),
  metWith: hasMany('shuttle-person', { asArrayAttr: true }),
  visits: hasMany('visit')
});
