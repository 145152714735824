import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  router: service(),

  visit: alias('model'),

  saveTask: task(function * (attrs) {
    this.visit.setProperties(attrs);

    try {
      yield this.visit.save();

      this.notify.success('Visit summary was updated successfully.');
      this.router.transitionTo('visits.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }).drop(),

  deleteTask: task(function * () {
    yield this.dialog.confirmDelete();

    try {
      yield this.visit.destroyRecord();

      this.notify.success('Visit summary was deleted successfully.');
      this.router.transitionTo('visits.list');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }).drop()
});
