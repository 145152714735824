import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['list-item', 'meeting-item'],

  // Actions
  ondelete: () => {},

  detailsLinkParams: computed('meeting.country', 'meeting', function () {
    return ['meetings.show', this.meeting];
  }),

  editLinkParams: computed('meeting.country', 'meeting', function () {
    return ['meetings.show.edit', this.meeting];
  })
});
