import Model from 'ember-data/model';
import attr from 'ember-data/attr';

export default Model.extend({

  field:     attr('string'),
  value:     attr('string'),
  updatedBy: attr(),
  updatedAt: attr('date')

});
