import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ApplicationRouteMixin from 'auth/mixins/application-route';
import Sentry from '@sentry/browser';

export default Route.extend(ApplicationRouteMixin, {
  dialog: service('satellite-dialog'),
  session: service(),

  afterModel() {
    this._setupSentryContext();
  },

  actions: {
    sessionAuthenticated() {
      this._setupSentryContext();
    },

    sessionInvalidated() {
      this.get('dialog').confirm({
        title: 'Session expired',
        message: 'Looks like your session has expired. Please, login to continue.',
        confirmLabel: 'Login'
      }).then(() => {
        this.send('login');
      });
    }
  },

  _setupSentryContext() {
    if (!this.session.isAuthenticated) { return; }

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: this.session.user.id,
        email: this.session.user.email
      });
    });
  }
});
