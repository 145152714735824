import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return {
      meeting: this.modelFor('meetings.show'),
      users: []
    };
  }
});
