import Service, { inject as service } from '@ember/service';
import ENV from 'security/config/environment';

export default Service.extend({
  ajax: service('auth-ajax'),

  async notify(ids, sections, exportSections = null) {
    if (ids.length < 1) { return; }

    let data = {
      ids,
      title: 'Notification',
      sections: await sections(),
      exportSections
    };

    return this.ajax.post(ENV.AUTH_HOST + '/api/security/notifications', {
      contentType: 'application/vnd.api+json; charset=utf-8',
      data: { data }
    });
  }
});
