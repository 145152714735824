import Route from '@ember/routing/route';
import moment from 'moment';

export default Route.extend({
  title: 'Calendar',

  queryParams: {
    month: { refreshModel: true }
  },

  beforeModel(transition) {
    let { month } = transition.queryParams;

    if (month && !(Date.parse(month) > 0)) {
      transition.abort();
      return this.transitionTo('calendar', { queryParams: { month: moment().format('YYYY-MM') } });
    }
  }
});
