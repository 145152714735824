import Service from '@ember/service';
import Ember from 'ember';

const LocalStorage = Service.extend({
  _key: 'recently-viewed',

  add(country) {
    let countries = this.retrieve().filter(function (item) {
      return item.id !== country.id;
    });

    countries.unshift(country);

    localStorage.setItem(this._key, JSON.stringify(countries.slice(0, 10)));
  },

  retrieve() {
    return JSON.parse(localStorage.getItem(this._key)) || [];
  }
});

const InMemory = Service.extend({
  init() {
    this._super(...arguments);
    this._data = [];
  },

  add(country) {
    this._data = this._data.filter(function (item) {
      return item.id !== country.id;
    });

    this._data.unshift(country);
    this._data.splice(10);
  },

  retrieve() {
    return this._data;
  }
});

const exported = Ember.testing ? InMemory : LocalStorage;

export default exported;
