import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';

export default Component.extend({
  store: service('store'),

  allowClear: true,
  label: 'Country',

  // Actions
  onchange: () => {},

  searchTask: task(function * (term) {
    yield timeout(250);

    return this.store.query('country', { filter: { term } });
  }).restartable()
});
