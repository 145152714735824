/* global Redactor */
import Component from '@ember/component';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import { task } from 'ember-concurrency';

export default Component.extend({
  notify: service('satellite-notify'),
  store: service(),

  classNames: ['box'],
  editMode: false,
  format: 'DD MMMM, YYYY, HH:mm',

  date: reads('overview.updatedAt'),
  user: reads('overview.updatedBy'),

  copyTask: task(function * () {
    let filter = {
      report: this.get('overview.report.prevReport'),
      key: this.get('overview.key')
    };

    try {
      let overview = yield this.get('store').query('report-overview', { filter });
      let content = overview.get('firstObject.body');

      this.set('content', content);
      run(() => Redactor('#overview-redactor textarea', 'source.setCode', content));
    } catch (e) {
      this.get('notify').error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  saveTask: task(function * () {
    this.get('overview').set('body', this.get('content'));

    try {
      yield this.get('overview').save();
      this.get('notify').success('Overview was saved successfully.');
      this.set('editMode', false);
    } catch (e) {
      this.get('notify').error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  actions: {
    edit() {
      this.setProperties({
        editMode: true,
        content: this.get('overview.body')
      });
    },

    cancel() {
      this.set('editMode', false);
    }
  }
});
