import Route from '@ember/routing/route';

export default Route.extend({
  async model() {
    let meeting = this.modelFor('meetings.show');
    let conductedBy = await meeting.hasMany('conductedBy').load();
    let institutions = await meeting.hasMany('institutions').load();
    let metWith = await meeting.hasMany('metWith').load();

    let attributes = {
      pillars: meeting.pillars,
      name: meeting.name,
      date: meeting.date,
      country: meeting.country,
      numberOfParticipants: meeting.numberOfParticipants,
      conductedBy: conductedBy.slice(),
      institutions: institutions.slice(),
      metWith: metWith.slice()
    };

    return { meeting, attributes };
  }
});
