import Sections from './-sections';
import asyncEach from './-async-each';

export default class PeopleSerializer {
  constructor(people) {
    this.people = people;
  }

  async serialize() {
    let sections = new Sections();

    await asyncEach(this.people, async (person) => {
      let attrs = { name: person.fullName, fields: [] };
      let membership = await person.get('primaryMembership');
      let institution = await person.get('primaryMembership.institution');

      if (institution) {
        attrs.fields.push({ label: 'Institution', value: institution.name });
      }

      if (membership) {
        let contacts = await membership.get('contacts');
        let email = contacts.findBy('label', 'e-mail');
        let phone = contacts.findBy('label', 'Phone');

        attrs.fields.push({ label: 'Position', value: membership.position });
        attrs.fields.push({ label: 'Email', value: email ? email.value : '' });
        attrs.fields.push({ label: 'Phone', value: phone ? phone.value : '' });
      }

      sections.push('contact', attrs);
    });

    return sections.toArray();
  }
}
