import Sections from './-sections';
import moment from 'moment';

export const STAGES_FIELDS = {
  audit: [
    { label: 'Date', field: 'date', type: 'date' },
    { label: 'Description', field: 'description' },
    { label: 'Attachments', field: 'attachments', type: 'attachments' }
  ],
  completion: [
    { label: 'Date', field: 'date', type: 'date' },
    { label: 'Description', field: 'description' },
    { label: 'Attachments', field: 'attachments', type: 'attachments' }
  ],
  'funds-transfer': [
    { label: 'First Date', field: 'firstDate', type: 'date' },
    { label: 'First Amount', field: 'firstAmount' },
    { label: 'Second Date', field: 'secondDate', type: 'date' },
    { label: 'Second Amount', field: 'secondAmount' }
  ],
  'grant-agreement': [
    { label: 'Date', field: 'date', type: 'date' },
    { label: 'Due Date', field: 'dueDate', type: 'date' },
    { label: 'Amount', field: 'amount' },
    { label: 'Upgrades', field: 'upgrades' },
    { label: 'Attachments', field: 'attachments', type: 'attachments' }
  ],
  'grant-request': [
    { label: 'Date', field: 'date', type: 'date' },
    { label: 'Amount', field: 'amount' },
    { label: 'Upgrades', field: 'upgrades' },
    { label: 'Attachments', field: 'attachments', type: 'attachments' }
  ],
  recommendations: [
    { label: 'Sent On', field: 'sentAt', type: 'date' },
    { label: 'Description', field: 'description' },
    { label: 'Attachments', field: 'attachments', type: 'attachments' }
  ]
};

export default class ProjectStageSerializer {
  constructor(stage) {
    this.stage = stage;
  }

  serialize() {
    let sections = new Sections();

    let title = this._titleize(this.stage.kind);
    let items = STAGES_FIELDS[this.stage.kind].map(({ label, type, field }) => {
      let value = this._normalizeField(type, this.stage.info[field]);

      return { label, value };
    });

    sections.push('list', { title, items });

    return sections.toArray();
  }

  _normalizeField(type, value) {
    if (type == 'date') {
      value = moment(value).format('DD MMMM YYYY');
    } else if (type == 'attachments' && value) {
      value = this._formatAttachments(value);
    }

    return value;
  }

  _formatAttachments(attachments) {
    return attachments.map((a) => {
      return `<a href="${decodeURIComponent(a.location)}">${a.name}</a>`;
    }).join(', ');
  }

  _titleize(string) {
    return string.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, (m) => m.toUpperCase());
  }
}
