import Datepicker from './datepicker';

export default Datepicker.extend({
  utcRegex: /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{1,4}Z$/,

  deserialize(value) {
    if (!this._isObject(value)) {
      return value;
    }

    Object.keys(value).forEach((key) => {
      if (this.utcRegex.test(value[key])) {
        value[key] = this._super(value[key]);
      }
    });

    return value;
  },

  serialize(value) {
    if (!this._isObject(value)) {
      return value;
    }

    let copy = { ...value };

    Object.keys(copy).forEach((key) => {
      if (copy[key] instanceof Date) {
        copy[key] = this._super(copy[key]);
      }
    });

    return copy;
  },

  _isObject(value) {
    return value && Object.prototype.toString.call(value) === '[object Object]';
  }
});
