import Route from '@ember/routing/route';

export default Route.extend({
  async model({ report_id }) {
    let report = await this.store.findRecord('report', report_id, {
      include: 'country,community,overviews',
      reload: true
    });

    let prevReport = await report.loadPrevReport();

    return { report, prevReport };
  },

  afterModel(model) {
    this.set('title', model.report.displayName);
  }
});
