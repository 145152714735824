import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  repoWidget: service(),
  shuttleWidget: service(),
  session: service(),

  queryParams: ['currentCountry'],

  actions: {
    mountShuttle() {
      this.shuttleWidget.open('people', this._countryScope());
    },

    mountRepo() {
      this.repoWidget.open('files', this._countryScope());
    }
  },

  _countryScope() {
    return this.session.currentCountry && {
      tags: [this.session.currentCountry.name]
    };
  }
});
