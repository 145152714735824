import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  router: service(),

  project: alias('model'),

  saveTask: task(function * (attrs) {
    this.project.setProperties(attrs);

    try {
      yield this.project.save();
      this.notify.success('Project was updated successfully.');
      this.router.transitionTo('projects.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  deleteTask: task(function * () {
    try { yield this.dialog.confirmDelete(); } catch (e) { return; }

    try {
      yield this.project.destroyRecord();
      this.notify.success('Project was deleted successfully.');
      this.router.transitionTo('projects.list');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
