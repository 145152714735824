import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  model() {
    return {
      country: this.session.currentCountry,
      date: new Date()
    };
  }
});
