import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';

export function toCurrency(value) {
  if (!isEmpty(value)) {
    if (typeof value === 'string') { value = parseFloat(value); }

    return value.toLocaleString('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
  }
}

export function toCurrencyHelper([value]) {
  return toCurrency(value);
}

export default helper(toCurrencyHelper);
