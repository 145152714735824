import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo, hasMany } from 'ember-data/relationships';
import { computed } from '@ember/object';

export const CATEGORIES = Object.freeze([
  'WJC Initiative',
  'Community Request',
  '3rd Party'
]);

export const CATEGORIES_COLLECTION = CATEGORIES.map((category) => ({ value: category, label: category }));

export const VENUE_TYPES = Object.freeze([
  'Synagogue',
  'Jewish Community Center (JCC)',
  'Community Office',
  'School / Kindergarten / Educational Complex',
  'Elderly Home',
  'Museum',
  'Yeshiva',
  'Sports Center',
  'Hessed',
  'JCC and Hessed',
  'Chabad',
  'Hillel'
]);

export const VENUE_TYPES_COLLECTION = VENUE_TYPES.map((category) => ({ value: category, label: category }));

export const STAGES = Object.freeze([
  'inception',
  'audit',
  'recommendations',
  'grant-request',
  'grant-agreement',
  'funds-transfer',
  'completion'
]);

export default Model.extend({
  // Attributes
  category: attr('string'),
  venueTypes: attr('', { defaultValue: () => [] }),
  description: attr('string'),
  stage: attr('string', { defaultValue: STAGES[0] }),
  isCompleted: attr('boolean'),

  createdBy: attr('', { readOnly: true }),
  createdAt: attr('date', { readOnly: true }),
  completedBy: attr('', { readOnly: true }),
  completedAt: attr('date', { readOnly: true }),
  updatedBy: attr('', { readOnly: true }),
  updatedAt: attr('date', { readOnly: true }),

  // Relations
  country: belongsTo('country'),
  venue: belongsTo('venue'),
  stages: hasMany('projectStage'),
  notifyUsers: hasMany('user', { asArrayAttr: true }),
  visits: hasMany('visit'),

  name: computed('venue.{name,community.name}', function () {
    return `${this.get('venue.name')}, ${this.get('venue.community.name')}`;
  }),

  nextStage: computed('stage', function () {
    let currentStage = STAGES.indexOf(this.stage);
    return STAGES[currentStage + 1];
  })
});
