import ENV from 'security/config/environment';

function optionsToQuery(options) {
  return Object.keys(options)
    .map((key) => options[key] && `${key}=${options[key]}`)
    .compact().sort().join('&');
}

export default function imgixURL(path, options = {}) {
  return ENV.IMGIX_HOST + path + '?' + optionsToQuery(options);
}
