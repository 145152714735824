import { helper } from '@ember/component/helper';
import moment from 'moment';

export function toDateRange(startDate, endDate) {
  let start = moment(startDate).format('DD MMMM YYYY');
  let end = moment(endDate).format('DD MMMM YYYY');

  if (start === end) {
    return start;
  } else {
    return `${start} to ${end}`;
  }
}

export function toDateRangeHelper([startDate, endDate]) {
  return toDateRange(startDate, endDate);
}

export default helper(toDateRangeHelper);
