import Component from '@ember/component';

export default Component.extend({
  tagName: '',

  selector: '#main > div',

  didInsertElement() {
    let node = document.querySelector(this.selector);

    if (node) {
      node.scrollTop = 0;
    }
  }
});
