import Model from 'ember-data/model';
import { belongsTo } from 'ember-data/relationships';
import attr from 'ember-data/attr';
import { reads } from '@ember/object/computed';

export default Model.extend({
  name: attr('string'),
  startsAt: attr('date'),
  endsAt: attr('date'),

  eventable: belongsTo({ polymorphic: true, async: false }),

  calendar: reads('eventable._internalModel.modelName')
});
