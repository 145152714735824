import Controller from '@ember/controller';
import { computed } from '@ember/object';
import moment from 'moment';

const currentMonth = moment().format('YYYY-MM');

export default Controller.extend({
  queryParams: ['month', 'calendars'],

  monthParamFormat: 'YYYY-MM',

  currentMonth: currentMonth,
  month: currentMonth,
  calendars: 'meeting,office365,project,report',

  _month: computed('month', function () {
    return new Date(this.month);
  }),

  nextMonth: computed('_month', function () {
    return moment(this._month).add(1, 'month').format(this.monthParamFormat);
  }),

  previousMonth: computed('_month', function () {
    return moment(this._month).subtract(1, 'month').format(this.monthParamFormat);
  })
});
