import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  notify: service('satellite-notify'),
  store: service(),
  router: service(),

  project: alias('model'),

  saveTask: task(function * (attrs) {
    let project = this.store.createRecord('project', attrs);

    try {
      yield project.save();

      this.notify.success('Project was created successfully.');
      this.router.transitionTo('projects.show', project);
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
