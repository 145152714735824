import Component from '@ember/component';
import { resolve } from 'rsvp';

const DRILL_TYPES = [
  {
    title: 'Exercise',
    items: [
      { label: 'Full scale exercise', isChecked: false },
      { label: 'Table top exercise', isChecked: false },
      { label: 'Function exercise', isChecked: false }
    ]
  },
  {
    title: 'Training',
    items: [
      { label: 'Training for professional team', isChecked: false },
      { label: 'Training for decision makers', isChecked: false },
      { label: 'Training for all team', isChecked: false },
      { label: 'Training for school', isChecked: false },
      { label: 'Magen David Adom Training', isChecked: false },
      { label: 'Safe school training', isChecked: false }
    ]
  },
  {
    title: 'Other',
    items: [
      { label: 'ABC', description: '(Assessment/Briefing/Consultation)', isChecked: false }
    ]
  }
];

export default Component.extend({
  // Actions
  oncancel: resolve,

  init() {
    this._super(...arguments);

    let selectedDrillTypes = (this.get('drillType') || '').split(', ');

    let drillTypes = DRILL_TYPES.map((drillType) => {
      let items = drillType.items.map((item) => {
        return Object.assign({}, item, { isChecked: selectedDrillTypes.indexOf(item.label) > -1 });
      });

      return Object.assign({}, drillType, { items });
    });

    this.setProperties({
      drillTypes
    });
  },

  actions: {
    save() {
      this.get('onsave')(this._selectedFields().join(', '));
    }
  },

  _allFields() {
    return this.get('drillTypes').mapBy('items').reduce((a, b) => a.concat(b), []);
  },

  _selectedFields() {
    return this._allFields().filterBy('isChecked').mapBy('label');
  }
});
