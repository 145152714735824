import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  router: service(),

  attributes: alias('model.attributes'),

  saveTask: task(function * () {
    let { meeting, attributes } = this.model;

    meeting.setProperties(attributes);

    try {
      yield meeting.save();

      this.notify.success('Meeting was updated successfully.');
      this.router.transitionTo('meetings.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }).drop(),

  deleteTask: task(function * () {
    yield this.dialog.confirmDelete();

    try {
      yield this.model.meeting.destroyRecord();

      this.notify.success('Meeting was deleted successfully.');
      this.router.transitionTo('meetings.list');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later');
    }
  }).drop()
});
