import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),

  queryParams: ['page', 'term'],
  page: 1,
  term: '',

  venues: alias('model'),

  // Needs to be 'model' instead of 'venues'
  isEmptyStateVisible: computed('model.[]', 'term', function () {
    return !this.term && this.model.length === 0;
  }),

  actions: {
    deleteVenue(venue) {
      return this.get('dialog').confirmDelete()
        .then(() => venue.destroyRecord())
        .then(() => this.get('notify').success('Venue was deleted successfully.'));
    },

    filter(term) {
      this.set('isLoading', true);
      this.transitionToRoute({ queryParams: { term, page: 1 } });
    }
  }
});
