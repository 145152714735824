import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  notify: service('satellite-notify'),
  store: service(),
  router: service(),

  report: alias('model'),

  saveTask: task(function * (attrs) {
    let report = this.store.createRecord('report', attrs);

    try {
      yield report.save();

      this.notify.success('Report was created successfully.');
      this.router.transitionTo('reports.show', report.id);
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
