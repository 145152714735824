import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  store: service(),
  router: service(),

  venue: alias('model'),

  saveTask: task(function * (attrs) {
    this.venue.setProperties(attrs);

    try {
      yield this.venue.save();
      this.notify.success('Venue was updated successfully.');
      this.router.transitionTo('venues');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  deleteTask: task(function * () {
    try { yield this.dialog.confirmDelete(); } catch (e) { return; }

    try {
      yield this.venue.destroyRecord();
      this.notify.success('Venue was deleted successfully.');
      this.router.transitionTo('venues');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
