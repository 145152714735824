import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Component.extend({
  // Actions
  onsave: () => {},
  oncancel: () => {},

  isDisabled: computed('model.{country,city,startDate,endDate,travellers}', 'visit.graphUrl', function () {
    if (this.visit.graphUrl) {
      return isEmpty(this.model.startDate)
        || isEmpty(this.model.endDate)
        || (!isEmpty(this.visit.country) && isEmpty(this.model.country))
        || (!isEmpty(this.visit.city) && isEmpty(this.model.city))
        || (!isEmpty(this.visit.travellers) && isEmpty(this.model.travellers));
    } else {
      return isEmpty(this.model.country)
        || isEmpty(this.model.city)
        || isEmpty(this.model.startDate)
        || isEmpty(this.model.endDate)
        || isEmpty(this.model.travellers);
    }
  }),

  init() {
    this._super(...arguments);

    this.model = {
      name: this.visit.name,
      country: this.visit.country,
      city: this.visit.city,
      startDate: this.visit.startDate,
      endDate: this.visit.endDate,
      travellers: this.visit.travellers,
      projects: this.visit.projects,
      meetings: this.visit.meetings,
      reports: this.visit.reports
    };
  },

  actions: {
    save() {
      if (this.model.city && this.model.travellers.length) {
        let travellers = this.model.travellers.slice();
        this.model.name = `${this._travellerNames(travellers)} in ${this.model.city}`;
      }

      this.onsave(this.model);
    }
  },

  _travellerNames(travellers) {
    if (travellers.length === 1) {
      return travellers[0].fullName;
    }

    let last = travellers.pop().fullName;
    let first = travellers.map((person) => person.fullName).join(', ');

    return `${first} and ${last}`;
  }
});
