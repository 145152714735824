import Component from '@ember/component';
import RSVP from 'rsvp';

export default Component.extend({
  classNameBindings: [':preparedness-level', 'disabled:-disabled'],

  // Actions
  onclick: RSVP.resolve,

  init() {
    this._super(...arguments);
    this.levels = [-1, 0, 1, 2, 3, 4, 5];
  }
});
