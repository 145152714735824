import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { computed } from '@ember/object';
import { belongsTo, hasMany } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  name: attr('string', { defaultValue: '' }),
  preparednessLevel: attr('number', { defaultValue: -1 }),
  date: attr('datepicker'),
  drillDate: attr('datepicker'),
  drillType: attr('string'),
  prevReport: attr('string'),
  nextReport: attr('string'),

  createdAt: attr('date', { readOnly: true }),
  createdBy: attr('', { readOnly: true }),
  updatedAt: attr('date', { readOnly: true }),
  updatedBy: attr('', { readOnly: true }),

  // Relations
  community: belongsTo('community'),
  country: belongsTo('country'),
  overviews: hasMany('reportOverview'),
  visits: hasMany('visit'),

  displayName: computed('name', 'drillType', function () {
    return this.drillType || this.name;
  }),

  loadPrevReport() {
    if (this.prevReport) {
      return this.store.findRecord('report', this.prevReport);
    }
  }
});
