import Sections from './-sections';
import ProjectStageSerializer from './project-stage-serializer';

export default class ProjectSerializer {
  constructor(project) {
    this.project = project;
  }

  async serialize() {
    let sections = new Sections();
    let stages = (await this.project.get('stages')).sortBy('createdAt');

    await this.project.get('venue');
    await this.project.get('venue.community');

    sections.push('title', { value: this.project.name });

    stages.forEach((stage) => {
      let serializedStage = new ProjectStageSerializer(stage).serialize();
      sections.concat(serializedStage);
    });

    return sections.toArray();
  }
}
