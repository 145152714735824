import Ember from 'ember';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import TYPE_MAP from './-type-map';
import moment from 'moment';

const ZOOM_MAP = {
  'meetings-and-minutes': ['continent'],
  'physical-infrastructure': ['continent', 'country'],
  'crisis-management': ['continent', 'country']
};

const EXPORT_MAP = {
  'meetings-and-minutes': {
    columns: ['label', 'community_meetings', 'authorities_meetings', 'conference_meetings', 'other_meetings'],
    headers: ['Region', 'Meetings with Community Representatives', 'Meetings with Authorities', 'Conferences', 'Other Meetings']
  },
  'physical-infrastructure': {
    columns: ['label', 'venues', 'in_progress', 'completed', 'audits', 'grant_requests', 'grant_aggreements', 'funds_transfer_count', 'funds_transfer'],
    headers: ['Region', 'Venues', 'Ongoing Projects', 'Completed Projects', 'Audits', 'Grant Requests', 'Grant Agreements', 'Funds Transferred Count', 'Funds Transferred']
  },
  'crisis-management': {
    columns: ['label', 'full_scale_exercise', 'table_top_exercise', 'function_exercise', 'professional_team_training', 'decision_makers_training', 'all_team_training', 'school_training', 'magen_david_adom_training', 'safe_school_training', 'abc_consultation', 'other_general'],
    headers: ['Region', 'Full scale exercise', 'Table top exercise', 'Function exercise', 'Training for professional team', 'Training for decision makers', 'Training for all team', 'Training for school', 'Magen David Adom Training', 'Safe school training', 'ABC', 'General']
  }
};

export default Controller.extend({
  router: service(),

  data: alias('model.data'),
  meta: alias('model.meta'),

  queryParams: ['type', 'from', 'to', 'continent', 'continent_label', 'country', 'country_label'],

  // Default type
  type: 'physical-infrastructure',

  formAction: computed('type', function () {
    return `//wjc-exporter.herokuapp.com/ss/${this.type}.csv`;
  }),

  tableComponentName: computed('type', function () {
    return 'stats-table/' + TYPE_MAP[this.type];
  }),

  zoomParams: computed('type', function () {
    return ZOOM_MAP[this.type];
  }),

  from: computed({
    get() {
      return this._from;
    },
    set(_, value) {
      this._from = value && moment(value).format('YYYY-MM-DD');
      return this._from;
    }
  }),

  to: computed({
    get() {
      return this._to;
    },
    set(_, value) {
      this._to = value && moment(value).format('YYYY-MM-DD');
      return this._to;
    }
  }),

  actions: {
    clearDateFilter() {
      this.setProperties({ from: null, to: null });
    },

    export() {
      let { columns, headers } = EXPORT_MAP[this.type];
      let form = document.getElementById('export-form');
      let data = { headers, rows: [] };

      this.model.data.forEach((row) => {
        data.rows.push(columns.map((column) => row[column]));
      });

      form.children[0].value = JSON.stringify({ data });

      if (!Ember.testing) {
        form.submit();
      }
    },

    zoomIn({ id, label }) {
      if (this.meta.last) {
        return this.router.transitionTo(this.meta.routeName, {
          queryParams: { currentCountry: this.meta.id || id }
        });
      }

      for (let i = 0; i < this.zoomParams.length; i++) {
        if (!this.get(this.zoomParams[i])) {
          this.set(this.zoomParams[i], id);
          this.set(this.zoomParams[i] + '_label', label);
          break;
        }
      }
    }
  }
});
