import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),

  tagName: 'a',
  attributeBindings: ['href'],
  classNames: ['country-item', 'flex-row', 'flex-center', 'animate'],

  href: computed('countryId', function () {
    return this.router.urlFor(...this._urlParams());
  }),

  click(e) {
    e.preventDefault();

    this.router.transitionTo(...this._urlParams());

    return false;
  },

  _urlParams() {
    return ['home', {
      queryParams: { currentCountry: this.countryId }
    }];
  }
});
