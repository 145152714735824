import Component from '@ember/component';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { dasherize } from '@ember/string';

const overviewsInfo = {
  visits: { title: 'Visits Overview', page: 'Visits' },
  'jc-general-information': { title: 'General Information', page: 'Jewish Community' },
  'jc-jewish-population': { title: 'Jewish Population', page: 'Jewish Community' },
  security: { title: 'Security Overview',  page: 'Security Information' },
  'security-threat-level': { title: 'Current Threat Level', page: 'Security Information' },
  'venue-audits': { title: 'Venue Security Audits', page: 'Venue Security Audits' },
  'crisis-management': { title: 'Crisis Management Overview', page: 'Crisis Management' },
  'local-authorities': { title: 'Agencies Cooperation Overview', page: 'Local Authorities' },
  'wjc-assistance': { title: 'WJC Assistance Overview', page: 'WJC Assistance' },
  'political-aspects': { title: 'Political Overview', page: 'Political Aspects' },
  israel: { title: 'Israel Overview', page: 'Israel' }
};

export default Component.extend({
  overview: reads('item.searchable'),
  country: reads('overview.country'),
  overviewMeta: computed('overview', function () {
    let key = this.get('overview.key');

    let title = overviewsInfo[key].title;
    let page = overviewsInfo[key].page;
    let route = `country.${dasherize(page)}`;

    return { title, page, route };
  })
});
