import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { CATEGORIES } from 'security/models/project';

export default Route.extend({
  session: service(),

  model() {
    return {
      country: this.session.currentCountry,
      venueTypes: [],
      category: CATEGORIES[0]
    };
  }
});
