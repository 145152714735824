import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';
import ReportSerializer from 'security/lib/report-serializer';

export default Controller.extend({
  notify: service('satellite-notify'),
  router: service(),
  session: service(),
  store: service(),
  userNotification: service(),

  report: alias('model.report'),
  users: alias('model.users'),

  sendTask: task(function * () {
    try {
      yield this._notifyUsers(this.report);

      this.notify.success('Notifications were send successfully.');
      this.router.transitionTo('reports.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  async _notifyUsers(report) {
    let ids = this.users.mapBy('id');
    let sections = await new ReportSerializer(report).serialize();
    return this.userNotification.notify(ids, () => [
      this._notificationBodySection(report),
      ...sections
    ], sections);
  },

  _notificationBodySection(report) {
    let { firstName, lastName } = this.session.user;
    let path = this.router.urlFor('reports.show');
    let body = `
      <p>${firstName} ${lastName} sent you a notification about report - ${report.displayName}</p>
      <p>
        See report details below or
        <a href="https://security.worldjewishcongress.org${path}">
          read and export in the Security Application (access required)
        </a>
      </p>
    `;

    return { type: 'text', attributes: { body } };
  }
});
