import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { belongsTo } from 'ember-data/relationships';

export default Model.extend({
  kind: attr('string'),
  info: attr('stageInfo'),
  isCompleted: attr('boolean', { defaultValue: false }),

  createdBy: attr('', { readOnly: true }),
  createdAt: attr('date', { readOnly: true }),
  completedBy: attr('', { readOnly: true }),
  completedAt: attr('date', { readOnly: true }),
  updatedBy: attr('', { readOnly: true }),
  updatedAt: attr('date', { readOnly: true }),

  project: belongsTo('project')
});
