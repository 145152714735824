import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  queryParams: {
    page: { refreshModel: true },
    term: { refreshModel: true }
  },

  model({ page, term }) {
    let query = {
      filter: {},
      page: { number: page, size: 10 },
      sort: 'name',
      include: 'community,community.country'
    };

    if (this.session.currentCountry) {
      query.filter.country = this.session.currentCountry.id;
    }

    if (term) {
      query.filter.term = term;
    }

    return this.store.query('venue', query);
  },

  setupController(controller) {
    this._super(...arguments);
    controller.set('isLoading', false);
  }
});
