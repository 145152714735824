import Route from '@ember/routing/route';

export default Route.extend({
  model({ project_id }) {
    return this.store.findRecord('project', project_id, {
      include: 'stages,venue,venue.community,venue.community.country',
      backgroundReload: true
    });
  },

  afterModel(project) {
    this.set('title', project.name);
  }
});
