import { helper } from '@ember/component/helper';

export function parameterize(value) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, '-') // swap any length of whitespace, underscore, hyphen characters with a single -
    .replace(/^-+|-+$/g, ''); // remove leading, trailing -
}

export default helper(
  ([value]) => parameterize(value)
);
