import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['timeline-section'],

  label: computed('stage.kind', function () {
    return this.stage.kind.replace('-', ' ');
  })
});
