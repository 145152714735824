import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';
import { isPresent } from '@ember/utils';

export function nl2br([value]) {
  if (isPresent(value)) {
    return htmlSafe(value.replace(/\n/g, '<br>'));
  }
}

export default helper(nl2br);
