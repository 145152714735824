import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import TYPE_MAP from './-type-map';

export default Route.extend({
  ajax: service('auth-ajax'),

  title: 'Stats',

  queryParams: {
    type: { refreshModel: true },
    from: { refreshModel: true },
    to: { refreshModel: true },
    continent: { refreshModel: true },
    country: { refreshModel: true }
  },

  model(params) {
    let { type, from, to, continent, country } = params;
    let filter = this._compact({ from, to, continent, country });

    return this.ajax.request(`/api/v1/stats/${TYPE_MAP[type]}`, { data: { filter } }).then(({ data }) => {
      return { data, meta: this._getMeta(params) };
    });
  },

  _compact(object) {
    return Object.keys(object).reduce((acc, key) => {
      if (object[key]) { acc[key] = object[key]; }
      return acc;
    }, {});
  },

  _getMeta({ type, continent, country }) {
    if (type === 'physical-infrastructure' && country) {
      return { last: true, routeName: 'projects', id: country };
    } else if (type === 'meetings-and-minutes' && continent) {
      return { last: true, routeName: 'meetings' };
    } else if (type === 'crisis-management' && country) {
      return { last: true, routeName: 'reports', id: country };
    }

    return {};
  }
});
