import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import MeetingSerializer from 'security/lib/meeting-serializer';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  notify: service('satellite-notify'),
  router: service(),
  session: service(),
  store: service(),
  userNotification: service(),

  saveTask: task(function * () {
    let meeting = this.store.createRecord('meeting', this.model);

    try {
      yield meeting.save();

      this._notifyUsers(meeting);

      this.router.transitionTo('meetings.show', meeting);
      this.notify.success('Meeting was created successfully.');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  async _notifyUsers(meeting) {
    let ids = this.model.notifyUsers.mapBy('id');

    let meetingSections = await new MeetingSerializer(meeting).serialize();
    return this.userNotification.notify(ids, () => [
      this._notificationBodySection(meeting)
    ], meetingSections);
  },

  _notificationBodySection(meeting) {
    let { firstName, lastName } = this.session.user;
    let path = this.router.urlFor('meetings.show', meeting);
    let body = `
      <p>${firstName} ${lastName} added new meetings and minutes - ${meeting.name}</p>
      <p>
        Please find attached a report or
        <a href="https://security.worldjewishcongress.org${path}">
          read in the Security Application (access required)
        </a>
      </p>
    `;

    return { type: 'text', attributes: { body } };
  }
});
