import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { hasMany } from 'ember-data/relationships';

export default Model.extend({
  // Attributes
  name: attr('string'),
  iso: attr('string'),
  threatLevel: attr('number'),
  updatedBy: attr('', { readOnly: true }),
  updatedAt: attr('date'),

  // Relations
  overviews: hasMany('overview'),
  meetings: hasMany('meeting'),
  communities: hasMany('community'),
  visits: hasMany('visit')
});
