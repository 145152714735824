import DS from 'ember-data';

export default DS.JSONAPISerializer.extend({
  normalize(modelClass, hash) {
    modelClass.eachRelationship((key, relationship) => {
      if (!relationship.options.asArrayAttr) { return; }

      let payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
      let ids = hash.attributes[payloadKey];

      if (ids) {
        let data = ids.map((id) => ({ id, type: relationship.type }));

        hash.relationships[payloadKey] = { data };

        delete hash.attributes[payloadKey];
      }
    });

    return this._super(modelClass, hash);
  },

  serializeAttribute(snapshot, json, key, attribute) {
    if (!attribute.options.readOnly) {
      this._super(...arguments);
    }
  },

  serializeHasMany(snapshot, json, relationship) {
    let { key, options } = relationship;

    if (options.asArrayAttr) {
      let hasMany = snapshot.hasMany(key, { ids: true });
      let payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');

      json.attributes[payloadKey] = hasMany;
    } else if (!relationship.options.readOnly) {
      this._super(...arguments);
    }
  }
});
