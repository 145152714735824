import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { captureException } from '@sentry/browser';

export default Controller.extend({
  dialog: service('satellite-dialog'),
  notify: service('satellite-notify'),
  router: service(),

  report: alias('model.report'),

  saveTask: task(function * (attrs) {
    this.report.setProperties(attrs);

    try {
      yield this.report.save();
      this.notify.success('Report was updated successfully.');
      this.router.transitionTo('reports.show');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop(),

  deleteTask: task(function * () {
    try { yield this.dialog.confirmDelete(); } catch (e) { return; }

    try {
      yield this.report.destroyRecord();
      this.notify.success('Report was deleted successfully.');
      this.router.transitionTo('reports.list');
    } catch (e) {
      captureException(e);
      this.notify.error('Something went wrong. Please, try again later.');
    }
  }).drop()
});
