import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'auth/mixins/authenticated-route';

export default Route.extend(AuthenticatedRouteMixin, {
  queryParams: {
    currentCountry: { refreshModel: true }
  },

  model({ currentCountry }) {
    return this._resolveCurrentCountry(currentCountry);
  },

  _resolveCurrentCountry(id) {
    if (id) {
      return this.store.findRecord('country', id).then((country) => {
        this.session.set('currentCountry', country);
      });
    } else {
      return this.session.set('currentCountry', null);
    }
  }
});
